import VueRouter from "vue-router";

const routes = [
    {
        name: "home",
        path: "/",
        component: () => import("./sealevel2100/views/Home.vue"),
    },
    {
        name: "map",
        path: "/map",
        component: () => import("./sealevel2100/views/Map.vue"),
    },
];

export default new VueRouter({
    routes,
});
