import "leaflet/dist/leaflet.css";
import "leaflet-layervisibility";
import "nouislider/distribute/nouislider.css";

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import "bootstrap/dist/js/bootstrap.min";
import "bootstrap/dist/css/bootstrap.min.css";

import "./style.css";

import Vue from "vue";
import VueNonreactive from "vue-nonreactive";
import VueRouter from "vue-router";

import router from "./router";
import Main from "./sealevel2100/Main.vue";

library.add(faTimes, faInfoCircle);
dom.watch();

Vue.use(VueNonreactive);
Vue.use(VueRouter);

// eslint-disable-next-line no-unused-vars
const SeaLevel2100 = new Vue({
    router,
    el: "#sealevel2100-app",
    render(h) {
        return h(Main);
    },
});
