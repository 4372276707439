var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "nav",
        {
          staticClass: "navbar navbar-expand-lg navbar-light bg-light fixed-top"
        },
        [
          _c("div", { staticClass: "container" }, [
            _vm._m(0),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "collapse navbar-collapse justify-content-end",
                attrs: { id: "navbarNavAltMarkup" }
              },
              [
                _c(
                  "div",
                  { staticClass: "navbar-nav" },
                  [
                    _c("router-link", {
                      attrs: { to: { name: "home" }, custom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var href = ref.href
                            var navigate = ref.navigate
                            var isExactActive = ref.isExactActive
                            return [
                              _c(
                                "a",
                                {
                                  staticClass: "nav-link",
                                  class: { active: isExactActive },
                                  attrs: { href: href },
                                  on: { click: navigate }
                                },
                                [
                                  _vm._v(
                                    "\n                            Home\n                        "
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("router-link", {
                      attrs: { to: { name: "map" }, custom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var href = ref.href
                            var navigate = ref.navigate
                            var isExactActive = ref.isExactActive
                            return [
                              _c(
                                "a",
                                {
                                  staticClass: "nav-link",
                                  class: { active: isExactActive },
                                  attrs: { href: href },
                                  on: { click: navigate }
                                },
                                [
                                  _vm._v(
                                    "\n                            Interaktive Karte\n                        "
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        attrs: {
                          href:
                            "https://www.researchgate.net/publication/345859766_Sea-Level_Rise_in_Northern_Germany_A_GIS-Based_Simulation_and_Visualization",
                          target: "_blank"
                        }
                      },
                      [_vm._v("Zum Paper")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        attrs: { href: "mailto:sealevel@hcu-hamburg.de" }
                      },
                      [_vm._v("Kontakt")]
                    )
                  ],
                  1
                )
              ]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("keep-alive", { attrs: { include: "Map" } }, [_c("router-view")], 1)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "navbar-brand",
        attrs: { target: "_blank", href: "https://www.hcu-hamburg.de/" }
      },
      [_c("img", { attrs: { src: "/assets/hculogo.png" } })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "navbar-toggler",
        attrs: {
          type: "button",
          "data-bs-toggle": "collapse",
          "data-bs-target": "#navbarNavAltMarkup",
          "aria-controls": "navbarNavAltMarkup",
          "aria-expanded": "false",
          "aria-label": "Toggle navigation"
        }
      },
      [_c("span", { staticClass: "navbar-toggler-icon" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }